import eventsHandler from '@/services/common/eventsHandler.js'

class itemTypeForm extends eventsHandler {
  constructor() {
    super()
    this._itemType = {}
    this._valid = false
  }

  get itemType() {
    const itemType = {
      ...this._itemType
    }
    return itemType
  }

  get valid() {
    return this._valid
  }

  get isNew() {
    return !this._itemType.id
  }

  setValid(valid) {
    this._valid = valid
    this._executeCallbacksOf('valid-change', valid)
  }

  resetItemType() {
    this._itemType = {}
    this._executeCallbacksOf('reset')
    this._executeCallbacksOf('update', {itemType: {}})
  }

  updateField(key, value) {
    this._itemType[key] = value
    this._executeCallbacksOf('update', {itemType: this._itemType})
    this._executeCallbacksOf('update-' + key, {key: this._itemType[key]})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }

  updateObject(itemType) {
    this._itemType = itemType
    this._executeCallbacksOf('update', {itemType: this._itemType})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }
}

export default new itemTypeForm()