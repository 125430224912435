<template>
  <FullScreenDialog
    v-model="itemTypeDialog"
    title="Famiglia"
    :routeFather="pathToGoBack"
  >
    <template v-slot:toolbar-extension>
      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab
          v-for="tab in itemTypeTabs"
          :key="tab.key"
          @click="$router.push({ name: tab.routeName }).catch(() => {})"
          >{{ tab.title }}</v-tab
        >
      </v-tabs>
    </template>
    <template v-slot:content>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="tabItem in itemTypeTabs" :key="tabItem.key">
          <v-card flat>
            <router-view></router-view>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>
    <template v-slot:footer-actions>
      <v-spacer></v-spacer>
      <v-btn
        text
        color="error"
        @click="$router.push({path: pathToGoBack})"
      >Annulla</v-btn>
      <v-btn
        text
        color="default"
        :disabled="!formValid"
        @click="submitForm"
        :loading="loading"
      >Salva</v-btn>
    </template>
  </FullScreenDialog>
</template>

<script>
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import itemTypesService from '@/services/itemTypes/itemTypes.service.js'
import itemTypeForm from '@/services/itemTypes/itemType.form.js'

export default {
  name: "ItemTypesRegistryNewForm",
  components: {
    FullScreenDialog
  },
  data: function() {
    return {
      tab: null,
      itemTypeDialog: this.openDialog,
      itemTypeTabs: this.tabs,
      formValid: false,
      itemType: undefined,
      loading: false
    };
  },
  mounted() {
    this.changeTabBasedOnRoute(this.$route.name)

    let self = this
    itemTypeForm.resetItemType()
    itemTypeForm.on('update', function(data) {
      self.itemType = data.itemType
    })

    itemTypeForm.on('valid-change', function(data) {
      self.formValid = data
    })
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false
    },
    pathToGoBack: {
      type: String,
      default: "/warehouse"
    },
    tabs: {
      type: Array,
      default: function() {
        return [
          {
            key: 0,
            title: "Generale",
            routeName: "ItemTypesGeneralNewFormTab",
          }
        ];
      }
    }
  },
  methods: {
    changeTabBasedOnRoute(routeName) {
      for(let i = 0; i < this.tabs.length; i++) {
        if(this.tabs[i].routeName == routeName) {
          this.tab = i
          break
        }
      }
    },
    submitForm() {
      this.loading = true
      itemTypesService.create(this.itemType).then((result) => {
        this.loading = false
        this.$router.push({path: this.pathToGoBack})
      })
    }
  },
  watch: {
    openDialog(newVal) {
      this.itemTypeDialog = newVal;
    }
  },
  computed: {
  }
};
</script>